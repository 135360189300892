// Toggle global options
$enable-gradients: true;
$enable-shadows: true;

//$offcanvas-box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175);

// Customize some defaults
//$body-color: #333;
//$body-bg: #fff;
//$border-radius: .4rem;
//$success: #7952b3;
$bootstrap-icons-font-dir: "/dist/fonts";
$font-family-sans-serif:
  // Cross-platform generic font family (default user interface font)
        system-ui,
          // Safari for macOS and iOS (San Francisco)
        -apple-system,
          // Windows
        "Segoe UI",
          // Android
        Roboto,
          // older macOS and iOS
        "Helvetica Neue"
          // Linux
        "Noto Sans",
        "Liberation Sans",
          // Basic web fallback
        Arial,
          // Sans serif fallback
        sans-serif,
          // Emoji fonts
        "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;

// Required
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/variables-dark";
@import "bootstrap/scss/maps";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";
@import "bootstrap/scss/root";
@import "bootstrap/scss/reboot";

@import "bootstrap/scss/type";
// @import "bootstrap/scss/images";
@import "bootstrap/scss/containers";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/tables";
// @import "bootstrap/scss/forms";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/transitions";
@import "bootstrap/scss/dropdown";
// @import "bootstrap/scss/button-group";
@import "bootstrap/scss/nav";
@import "bootstrap/scss/navbar"; // Requires nav
@import "bootstrap/scss/card";
@import "bootstrap/scss/breadcrumb";
// @import "bootstrap/scss/accordion";
@import "bootstrap/scss/pagination";
@import "bootstrap/scss/badge";
@import "bootstrap/scss/alert";
// @import "bootstrap/scss/progress";
@import "bootstrap/scss/list-group";
@import "bootstrap/scss/close";
// @import "bootstrap/scss/toasts";
//@import "bootstrap/scss/modal"; // Requires transitions
// @import "bootstrap/scss/tooltip";
@import "bootstrap/scss/popover";
// @import "bootstrap/scss/carousel";
// @import "bootstrap/scss/spinners";
@import "bootstrap/scss/offcanvas"; // Requires transitions
// @import "bootstrap/scss/placeholders";

// Helpers
@import "bootstrap/scss/helpers";

// Utilities
@import "bootstrap/scss/utilities/api";


//
// Custom styles
//
@import "bootstrap-icons/font/bootstrap-icons";
@import "syntax/syntax";

// logo
.logo:hover {
  img {
    transform: rotate(-10deg) scale(1.2);
  }
}

// post
.post {
  .post-content {
    blockquote {
      padding: 0 1em;
      color: $text-muted;
      border-left: $border-width $border-style $border-color;
    }
    table {
      @extend .table, .table-striped, .table-bordered;
    }
  }
}

// table of contents
.toc {
  top: 4rem;
  @media (min-width: 992px) {
    height: calc(100vh - 7rem);
    overflow-y: auto;
  }

  ul {
    list-style: none;
  }

  li {
    @extend .justify-content-between, .text-truncate;

    a {
      display: block;
      padding: $nav-link-padding-y $nav-link-padding-x;
      border-left: 2px $border-style $border-color;
    }

    a:hover, a.active {
      color: $nav-link-hover-color;
      border-left: 2px $border-style $link-hover-color;
    }
  }
}


// color mode
.color-modes {
  .theme-icon-active {
    width: 16px;
  }
  .dropdown-menu {
    padding: .25rem;

    li + li {
      margin-top: .125rem;
    }
  }

  .dropdown-item {
    @include border-radius(.25rem);
  }

  .active {
    font-weight: 600;

   .bi {
      display: block !important; // stylelint-disable-line declaration-no-important
    }
  }
}
