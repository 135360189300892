figure.highlight,
pre.highlight {
  padding: 1rem 1.5rem;
  margin: 1.5rem 0;
  overflow: auto;
  line-height: 1.4rem;
  position: relative;
  border: $card-border-width solid $card-border-color;
  background-color: var(--bs-tertiary-bg);
}

.highlighter-rouge {
  font-weight: 300;
  position: relative;
}

p code.highlighter-rouge,
p kbd {
  color: #cc6666;
  font-size: 1rem;
  padding: 2px 4px;
  border-radius: 2px;
  word-break: break-word;
}

kbd {
  border: 1px solid $gray-800;
  border-radius: 4px;
}

.rouge-table .code pre {
  overflow: hidden;
}

.rouge-table .code pre {
  overflow: hidden;
}

table.rouge-table,
table.rouge-table tr,
table.rouge-table td,
table.rouge-table pre {
  border: 0;
  padding: 0;
  margin: 0;
}

table.rouge-table td.gutter {
  padding-right: .5rem;
  border-right: 1px solid $gray-500;
}

table.rouge-table td.code {
  padding-left: .5rem;
}

figure pre {
  margin-bottom: 0;
}

.lineno {
  text-align: right;
}

.highlight,
.highlight pre,
.highlight table {
  border-radius: 4px;
  font-size: 1rem;
}

@include color-mode(light) {
  @import "github";
}
@include color-mode(dark) {
  @import "native";
}